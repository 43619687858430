

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const App = props => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const location = useLocation()
  useEffect(() => {
    console.log('Initiating SAML check.', 'SAML');
    if (location.pathname?.slice(1) === "adrianse") {
      axios({
        method: 'GET',
        url: 'https://sso1.letsnhance.com/api/whoami',
        withCredentials: true
      })
        .then(response => {
          console.log(response.data.user, 'SAML');

          if (response.data.user.nameID) {
            setEmail(response.data.user.nameID);
            setLoading(false);
          }
          else {
            RedirectToLogin();
          }
        })
        .catch(error => {
          console.log(error, 'SAML');
          RedirectToLogin();
        })
    }
  }, []);

  const RedirectToLogin = () => {
    window.location.replace('https://sso1.letsnhance.com/api/login');
  }

  if (loading)
    return <p>loading ...</p>
    return (
        <p>Hello {email}!</p>
      );
    }
    
    export default App;
    

